@import '../variables.scss';

@mixin media-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-down($breakpoint) {
  @media (max-width: ($breakpoint - 0.02)) {
    @content;
  }
}

@mixin media-between($breakpoint-from, $breakpoint-to) {
  @media (min-width: $breakpoint-from) and (max-width: ($breakpoint-to - 0.02)) {
    @content;
  }
}

@mixin responsive-font-size($desktopFontSize, $tabletFontSize, $mobileFontSize) {
  font-size: $desktopFontSize;

  @include media-down($vp--1280) {
    font-size: $tabletFontSize;
  }

  @include media-down($vp--741) {
    font-size: $mobileFontSize;
  }
}

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin vp--740 {
  @media (max-width: $vp--741) {
    @content;
  }
}

@mixin vp--740-landscape {
  @media (max-width: $vp--741 + 1) and (orientation: landscape) {
    @content;
  }
}

@mixin vp--tablet {
  @media (min-width: $vp--741 + 1) and (max-width: $vp--1366) {
    @content;
  }
}

@mixin vp--tablet2 {
  @media (max-width: $vp--1280) {
    @content;
  }
}

@mixin vp--tablet3 {
  @media (max-width: $vp--1180) {
    @content;
  }
}
