@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.seventhScreenContainer {
  width: 100%;
  height: size-rem(1110px);
  overflow: hidden;
  position: relative;
  @include vp--740 {
    height: size-rem(1100px, true);
    background-color: #f1f1f1;
  }
}
.swiper {
  padding: 0px !important;
}
.swiperSlide {
  margin: 0px !important;
}
.seventhScreenTitle {
  position: absolute;
  top: size-rem(160px);
  left: size-rem(80px);
  z-index: 3;
  @include vp--740 {
    top: size-rem(60px, true);
    left: 5%;
    width: size-rem(360px, true);
  }
}
.seventhScreenTitle p {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 95%;
  letter-spacing: -0.02em;
  color: $clr-black;
  margin: 0;
  @include vp--740 {
    font-size: size-rem(40px, true);
  }
}

.seventhScreenTitle :last-child {
  margin-left: size-rem(60px);
  @include vp--740 {
    margin-left: size-rem(0px);
  }
}

.seventhScreenWrapper {
  @include flex-row;
  position: relative;
  top: size-rem(250px);
  background: $clr-light-grey;
  width: 90%;
  margin: auto;
  height: size-rem(803px);
  overflow: hidden;
  @include vp--740 {
    @include flex-column;
    width: 100%;
    top: size-rem(100px, true);
    height: initial;
  }
}
.seventhScreenBoxDescription {
  width: 37.5%;
  @include vp--740 {
    width: 100%;
  }
}

.seventhScreenImgContainer {
  width: 62.5%;
  height: 100%;
  @include vp--740 {
    width: 100%;
    height: size-rem(367px, true);
    margin-top: size-rem(34px, true);
  }
}

.sevenScreenImageBoxSwiper {
  @include vp--740 {
    height: 100%;
  }
}

.seventhScreenBoxImg {
  width: 100%;
  @include vp--740 {
    width: 100%;
  }
}
.seventhScreenTabMenu {
  @include flex-row_sb;
  margin: auto;
  margin-top: size-rem(120px);
  width: 90%;
  @include vp--740 {
    margin-top: size-rem(90px, true);
    margin-left: 5%;
    width: 90%;
    overflow-x: scroll;
    height: size-rem(48px, true);
    position: relative;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
    }
  }
}

.seventhScreenTabMenuWrapper {
  @include flex-row;
  margin-top: 0;
  width: 90%;
  flex-wrap: wrap;
  height: size-rem(115px);
  gap: size-rem(8px);
  @include vp--740 {
    position: absolute;
    overflow-x: visible;
    height: size-rem(48px, true);
    flex-wrap: nowrap;
    gap: size-rem(8px, true);
  }
}

.seventhScreenTab,
.seventhScreenTabActive {
  height: size-rem(51px);
  padding-left: size-rem(20px);
  padding-right: size-rem(20px);
  font-family: $primary-font-family;
  background: $clr-light-grey;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 120%;
  color: $clr-black;
  border: 1px solid transparent;
  cursor: pointer;
  box-sizing: border-box;
  @include vp--740 {
    padding: size-rem(12px, true) size-rem(18px, true);
    font-size: size-rem(16px, true);
    height: fit-content;
    text-transform: uppercase;
    margin-right: initial;
    white-space: nowrap;
  }
}

.seventhScreenTabActive {
  border: 1px solid #c4c4c4;
  border-radius: size-rem(40px);
  @include vp--740 {
    border-radius: size-rem(40px, true);
    box-sizing: border-box;
  }
}

.seventhScreenDescriptionContainer {
  width: 100%;
  min-height: size-rem(108px);
  margin-top: size-rem(30px);
  @include vp--740 {
    min-height: size-rem(208px, true);
    margin-top: size-rem(30px, true);
  }
}

.seventhScreenDescription {
  width: 82%;
  height: size-rem(108px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(18px);
  line-height: 140%;
  letter-spacing: -0.02em;
  color: $clr-dark-grey;
  margin-left: size-rem(50px);
  @include vp--740 {
    font-size: size-rem(16px, true);
    width: 85%;
    margin-left: 7%;
    margin-top: size-rem(30px, true);
    height: fit-content;
  }
}

.seventhScreenInfo {
  margin-left: size-rem(50px);
  margin-top: size-rem(68px);
  height: size-rem(110px);
  @include vp--740 {
    margin-left: 7%;
    height: size-rem(110px, true);
    margin-top: size-rem(0px, true);
    display: flex;
    flex-direction: row;
  }
}

.seventhScreenInfo p {
  margin: 0;
  color: $clr-dark-grey;
  font-size: size-rem(18px);
  @include vp--740 {
    font-size: size-rem(18px, true);
  }
}

.seventhScreenInfo .seventhScreenInfoArea {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: size-rem(85px);
  line-height: 86%;
  letter-spacing: -0.02em;
  color: $clr-black;
  margin: 0;
  sup {
    font-size: size-rem(50px);
  }
  @include vp--740 {
    font-size: size-rem(40px, true);
    sup {
      font-size: size-rem(30px, true);
    }
  }
}

.seventhScreenBtn {
  margin-left: size-rem(50px);
  margin-top: size-rem(80px);
  width: fit-content !important;
  padding: size-rem(26px) size-rem(30px) !important;
  @include vp--740 {
    margin-left: 12%;
    width: size-rem(165px, true) !important;
    height: size-rem(51px, true) !important;
  }
}

.seventhScreenSubTextBottom {
  margin-left: size-rem(50px);
  margin-top: size-rem(30px);
  font-size: size-rem(14px);
  color: $clr-dark-grey;
  width: 70%;
  @include vp--740 {
    margin-left: 7%;
    margin-top: size-rem(10px, true);
    font-size: size-rem(13px, true);
    width: 90%;
  }
}

.sevenScreenSvg {
  position: absolute;
  width: size-rem(600px);
  height: size-rem(600px);
  top: size-rem(-1300px);
  left: size-rem(1000px);
}

.seventhScreenInfoBoxMobile {
  display: flex;
  flex-direction: column;
}
