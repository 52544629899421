@mixin flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column_sb {
  @include flex-column;
  justify-content: space-between;
}

@mixin flex-row_sb {
  @include flex-row;
  justify-content: space-between;
}
