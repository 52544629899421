@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.buttonBigCall {
  border: 1px solid $clr-grey;
  padding: size-rem(10px);
  border-radius: size-rem(37px);
  width: size-rem(260px);
  height: size-rem(73px);
  box-sizing: border-box;
  @include flex-row;
  align-items: center;
  justify-content: center;
  background-color: $clr-light-grey;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    background-color: $clr-black;
    .buttonBigCallText {
      color: $clr-white;
    }
    transition: all 0.5s ease;
  }
  &:focus {
    background-color: $clr-dark-grey;
    .buttonBigCallText {
      color: $clr-white;
    }
    transition: all 0.5s ease;
  }

  @include vp--740 {
    width: size-rem(280px, true);
    height: size-rem(80px, true);
    border-radius: size-rem(40px, true);
    padding: size-rem(6px, true);
  }
}

.buttonBigCallImg {
  width: size-rem(80px) !important;
  height: size-rem(53px) !important;
  border-radius: size-rem(37px) !important;

  overflow: hidden !important;
  @include vp--740 {
    width: size-rem(88px, true) !important;
    height: size-rem(58px, true) !important;
    border-radius: size-rem(54px, true) !important;
    margin-left: size-rem(5px, true) !important;
  }
}

.buttonBigCallImg img {
  width: size-rem(80px) !important;
  height: size-rem(53px) !important;
  @include vp--740 {
    width: size-rem(88px, true) !important;
    height: size-rem(58px, true) !important;
  }
}

.buttonBigCallText {
  font-weight: 300;
  font-family: $main-font-family;
  font-size: size-rem(16px);
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: $clr-black;
  text-align: center;

  display: flex;
  align-items: baseline;
  @include vp--740 {
    font-size: size-rem(16px, true);
    margin-left: size-rem(0px, true);
  }
}
