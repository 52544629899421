// COLORS VARIABLES
$clr-white: #ffffff;
$clr-black: #1c1d1f;
$clr-dark-black: #1c1c1c;
$clr-light-grey: #edeeed;
$clr-medium-grey: #f8f8f8;
$clr-grey: #c4c4c4;
$clr-lightdark-grey: #979797;
$clr-dark-grey: #636363;
$clr-green: #6a8860;
$clr-light-green: #77a767;
$clr-red: #de3737;
$clr-darker-grey: #383838;
$clr-middle-grey: #535353;
$clr-grey-1: #e3e4e3;
$clr-grey-2: #e1e1e1;
$clr-silver-chalice: #adadad;
$clr-shark: #24292e;
$clr-wild-sand: #f5f5f5;

// FONTS VARIABLES
$main-font-family: 'Pressura Standart Text', 'Segoe UI', 'Roboto', 'Oxygen', 'GT Pressura Pro Trial', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$primary-font-family: 'GT Pressura LC Mono Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

//Main breakpoints:

$vp--375: 375px;
$vp--414: 414px;
$vp--560: 560px;
$vp--741: 741px;
$vp--768: 768px;
$vp--820: 820px;
$vp--1024: 1024px;
$vp--1180: 1180px;
$vp--1280: 1280px;
$vp--1366: 1366px;
$vp--1601: 1601px;
$vp--1920: 1920px;
$vp--667-landscape: 667px;

// Retina
//---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

$mobile: $vp--741;
$tablet: $vp--1280;
